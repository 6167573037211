<template>
  <div>
 

    <vx-card>
      <div class="flex justify-between align-items-center mb-8">
        <h2 class="mb-0">Chidren</h2>
      </div>
      
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="childrenData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between mb-6"
        >
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-5">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-xs="12" vs-sm="6" vs-lg="6">
              <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="left" vs-xs="12" vs-sm="6" vs-lg="6">
              <vs-button color="primary" type="filled" @click="addNewChild">Add Child</vs-button>
            </vs-col>
          </vs-row>
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Child ID</vs-th>
          <vs-th>Room</vs-th>
          <vs-th>Age Group</vs-th>                  
          <vs-th>Primary Parent/Guardian</vs-th>                  
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
           
            <vs-td :data="data[indextr].children.fullName">
              {{ data[indextr].children.fullName | capitalize }}
            </vs-td>

            <vs-td >
                {{ ("undefined"!= data[indextr].children.childId) ? data[indextr].children.childId :'' }}
            </vs-td>
              
            <vs-td :data="data[indextr].children.room[0].name">              
              {{ data[indextr].children.room[0].name }}
            </vs-td>

            <vs-td :data="data[indextr].children.ageGroupId.ageGroup">
              {{ data[indextr].children.ageGroupId.ageGroup | capitalize }}
            </vs-td>
            
            <vs-td>
           
            </vs-td>          
                      
            
            <vs-td class="whitespace-no-wrap">
              <a href="#" class="nav-link d-flex align-items-center active" @click.stop="viewHandler(data[indextr].children._id)">
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import _ from "lodash";

  export default {
    components: {
      "v-select": vSelect,
    },
    data() {
      return {
        isMounted: false,
        totalDocs: 0,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "asc",
          limit: 50,
          page: 1        
        },
        hildrenData: [],        
        serverResponded: false,
        searchQuery: "",
        awaitingSearch: false,
        addNewDataSidebar: false,
        sidebarData: {},
        noDataText:"Currently no child available",
      }
    },
    methods: {
      ...mapActions("center", [
        "getDirectorChildrenList",        
      ]),
      // ...mapActions("group", ["getAllCountries"]),
       getChildrenData() {
        this.dataTableParams.search = this.searchQuery;
        this.dataTableParams.directorId = this.director.id;
        this.$vs.loading()
      
        this.getDirectorChildrenList(this.dataTableParams)
          .then((res) => {
            // let centers = this.$store.state.center.centers;
            // console.log( res );
            this.childrenData = res.data.data.docs;
            this.totalDocs = res.data.data.pagination.total;
            this.page = res.data.data.pagination.page;

          /*   _.foreach( this.childrenData, (o,k)=>{
                let primaryParent = _.find(o.parents ,(p) => {
                    return p.isPrimary == true;
                });
                _.forEach( o.parentData, (p,ki)  => {
                    if( o._id == primaryParent.parentId ){
                      // o.parentData[ki].isPrimary = true ;
                      this.childrenData[k].mainParent = p;
                    }
                });
            });

            console.log( this.childrenData); */




            this.serverResponded = true;
            if( this.childrenData.length == 0 && '' !=this.dataTableParams.search ) {
              this.noDataText= "Cannot find room with search text: '"+this.dataTableParams.search+"'";
              } 
            this.$vs.loading.close()
          })
          .catch(err => {
            this.$vs.loading.close()
            // console.error(err)
          })
      },
      handleSearch(searching) {
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getChildrenData();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getChildrenData();
      },
      handleSort(key, active) {
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getChildrenData();
      },
      viewHandler(id){
        this.$router.push({name: 'director-children-view', params: { id: id } });
      },
      addNewChild(id){
        this.$router.push({name: 'director-children-add' });
      },
      editDetailHandler(data,id) {
        this.sidebarData = data;
        this.dataId = id;       
      },      
      refreshData(){     
      },
    },
    mounted(){
      // console.log(this.director);
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
      director(){
        return this.$store.state.AppActiveUser;
      }

    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getChildrenData();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getChildrenData();
        }
      },
      "dataTableParams.sort": function(newSort, oldSort) {
        if (newSort !== oldSort) {
          this.dataTableParams.page = 1;
          this.dataTableParams.sort = newSort;
          this.getChildrenData();
        }
      },      
      searchQuery: function (val) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getChildrenData();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
     
     

    },
    created() {
      let self = this;
      setTimeout(() => {
        self.getChildrenData();     
      }, 5000); 
    }
  }

</script>

<style>
  .vs-input--input.hasIcon:focus + .vs-input--placeholder {
    display: none !important;
  }
</style>
